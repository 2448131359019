import React, { FC, useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

import { IConfluenceProps } from "../../../interfaces/confluence-props";
import {
  ConfluenceWrapper,
  ConfluenceText,
  ConfluenceImage,
} from "./index.styled";
import iconPalm from "../../../images/icon-palm-large.svg";
const Confluence: FC<IConfluenceProps> = ({ ...props }) => {
  const confluenceWrapperRef = useRef<HTMLDivElement>(null);
  const confluenceText = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: confluenceWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=20% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          confluenceText?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        ),
    });
  });

  return (
    <ConfluenceWrapper ref={confluenceWrapperRef}>
      <ConfluenceImage src={iconPalm} alt={"New Condominiums in Newport Beach"}/>
      <ConfluenceText ref={confluenceText}>{props.text}</ConfluenceText>
    </ConfluenceWrapper>
  );
};

export default Confluence;
