import React, { FC, useRef } from "react";
import introArrow from "../../images/icon-arrow.svg";
import {
  IntroImageArrow,
  IntroImageItem,
  IntroImageWrapper,
} from "./index.styled";
import { IIntroImageProps } from "../../interfaces/intro-image-props";
import { TweenLite } from "gsap";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const IntroImage: FC<IIntroImageProps> = ({ imageSrc }) => {
  const IntroImageWrapperRef = useRef<HTMLDivElement>(null);

  const gotoAnchor = () => {
    TweenLite.to(window, 0.8, {
      scrollTo: IntroImageWrapperRef?.current?.offsetHeight,
    });
  };

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `);

  return (
    <IntroImageWrapper ref={IntroImageWrapperRef}>
      <IntroImageItem>
        <Img
          fluid={
            data.allImageSharp.edges.find((element: any) => {
              return element.node.fluid.src.split("/").pop() === imageSrc;
            }).node.fluid
          }
          alt={"New homes for sale in Newport Beach"}
          loading={"eager"}
        />
      </IntroImageItem>
      <IntroImageArrow src={introArrow} onClick={gotoAnchor} />
    </IntroImageWrapper>
  );
};

export default IntroImage;
