import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const AssetsWrapper = styled.div`
  display: flex;
  margin: 0 10% 72px;
  ${mediaMax.tabletLandscape`
    flex-wrap: wrap;
    margin: 0 6.25%;
  `}
`;
export const AssetsLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 38.3%;
  .gatsby-image-wrapper {
    margin-bottom: 98px;
    ${mediaMax.tabletLandscape`
      width: 60.5%;
      margin: 0 0 48px 25%;
    `}
    ${mediaMax.phoneXL`
      width: 100%;
      margin: 0 0 48px 0;
    `};
  }
  ${mediaMax.tabletLandscape`
width: 100%;
`}
`;
export const AssetsRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 48.5%;
  ${mediaMax.tabletLandscape`
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;
    .gatsby-image-wrapper {
      width: 31%;
    }
    div {
      width: 61.3%;
    }
  `};
  ${mediaMax.phoneXL`
    flex-wrap: wrap;
    flex-direction: column-reverse;
    div {
      width: 100%;
      padding-bottom: 48px;
    }
    .gatsby-image-wrapper {
      width: 100%;
    }
  `};
`;
