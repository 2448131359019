import styled from "styled-components";
import { mediaMin, mediaMax } from "../../../helpers/MediaQueries";

export const ConfluenceWrapper = styled.div`
  display: flex;
  padding: 33px 0 72px;
  position: relative;
  ${mediaMax.tabletLandscape`
      padding: 0 0 48px;
  `}
  ${mediaMax.phoneXL`
      padding: 0 6.25% 48px;
  `}
`;

export const ConfluenceImage = styled.img`
  position: absolute;
  bottom: 30%;
  left: 5.5%;
  ${mediaMin.desktop`
    left: 7.5%;
  `}
  ${mediaMax.tabletLandscape`
    display: none;
  `}
`;

export const ConfluenceText = styled.p`
  font: normal normal 100 26px/32px Optima;
  margin-left: 15%;
  max-width: 360px;
  color: #535d53;
  font-style: italic;
  ${mediaMax.tabletLandscape`
    max-width: 500px;
    margin: 0 auto;
  `}
`;
