import React, { useContext, useEffect } from "react";
import useWindowSize from "../helpers/UseWindowSize";
import Wellness from "../components/amenities/wellness";
import Assets from "../components/amenities/assets";
import Confluence from "../components/amenities/confluence";
import FullscreenImage from "../components/fullscreen-image";
import PageTransitionSection from "../components/page-transition-section";
import IntroImage from "../components/intro-image";
import IntroText from "../components/intro-text";
import FirstLoadContext from "../context";
import { Helmet } from "react-helmet";
import FooterSeo from "../components/footer-seo";

const introText = {
  title: "HOME AMENITIES",
  text: "Tailored amenities were designed to enhance the graceful Newport Beach lifestyle. A private, serene, heated pool and hot tub spa stand at the center of the new home community. A fitness center is outfitted with the latest fitness equipment and spa-style bathrooms. Your home extends to the poolside cabana for reading the newspaper on a cool afternoon or lounging by the pool on a summer’s day.",
};
const wellnessText = {
  text: "The ultimate confluence between a poolside lounge and zen-like retreat.",
};

const Amenities = () => {
  const { setFirstLoad } = useContext(FirstLoadContext);
  useEffect(() => {
    setFirstLoad(false);
  }, [setFirstLoad]);
  const size = useWindowSize();
  return (
    <>
      <Helmet>
        <title>Home Amenities at Parkhouse | Parkhouse Residences Newport Beach</title>
        <meta name="description" content="Parkhouse Residences offers a long list of tailored amenities such as a heated pool, hot tub spa, Fitness center and cabana." />
      </Helmet>
      <IntroImage
        imageSrc={
          size.width < 1025 ? "amenities-hero-tab.jpg" : "amenities-hero.png"
        }
      />
      <IntroText title={introText.title} text={introText.text} />
      <Wellness />
      {size.width > 1025 && <Confluence text={wellnessText.text} />}
      <FullscreenImage
        imageSrc={"amenities-2.jpg"}
        withPadding={size.width < 1025 ? "87.5%" : "80%"}
      />
      {size.width < 1025 && <Confluence text={wellnessText.text} />}

      <Assets />
      <PageTransitionSection text={"CONTINUE TO"} linkTo={"neighborhood"} />
      <FooterSeo />
    </>
  );
};

export default Amenities;
