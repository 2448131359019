import React, { FC, useEffect, useRef } from "react";
import useWindowSize from "../../../helpers/UseWindowSize";
import { AssetsLeft, AssetsRight, AssetsWrapper } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Assets: FC = () => {
  const assetsWrapperRef = useRef<HTMLDivElement>(null);
  const assetsRightRef = useRef<HTMLDivElement>(null);
  const assetsLeftRef = useRef<HTMLDivElement>(null);
  const assetsHeaderRef = useRef<HTMLHeadingElement>(null);
  const assetsTextImgRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    ScrollTrigger.create({
      trigger: assetsWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=30% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          assetsRightRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          assetsLeftRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        )
        .fromTo(
          assetsHeaderRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        )
        .fromTo(
          assetsTextImgRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        ),
    });
  }, []);

  const data = useStaticQuery(graphql`
    query {
      amenities3: file(relativePath: { eq: "amenities-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      amenities4: file(relativePath: { eq: "amenities-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const size = useWindowSize();
  return (
    <AssetsWrapper ref={assetsWrapperRef}>
      <AssetsLeft ref={assetsRightRef}>
        <Img
          fluid={data.amenities3.childImageSharp.fluid}
          alt={"New Condominiums in Newport Beach"}
          loading={"eager"}
        />

        {size.width > 1025 && (
          <div>
            <h2 className={"body-title"} ref={assetsHeaderRef}>
              PROTECT YOUR ASSETS
            </h2>
            <p className={"body-copy"} ref={assetsTextImgRef}>
              Each residence at Parkhouse comes with its own private garage —
              many with extra space for customized storage. Owners also have the
              option to add a lift, allowing multiple cars or other toys to be
              stored in your own secure garage. EV charging can be easily
              installed.
            </p>
          </div>
        )}
      </AssetsLeft>

      <AssetsRight ref={assetsLeftRef}>
        <Img
          fluid={data.amenities4.childImageSharp.fluid}
          alt={"New homes for sale in Newport Beach"}
          loading={"eager"}
        />
        {size.width < 1025 && (
          <div>
            <h2 className={"body-title"} ref={assetsHeaderRef}>
              PROTECT YOUR ASSETS
            </h2>
            <p className={"body-copy"} ref={assetsTextImgRef}>
              Each residence at Parkhouse comes with its own private garage —
              many with extra space for customized storage. Owners also have the
              option to add a lift, allowing multiple cars or other toys to be
              stored in your own secure garage. EV charging can be easily
              installed.
            </p>
          </div>
        )}
      </AssetsRight>
    </AssetsWrapper>
  );
};

export default Assets;
