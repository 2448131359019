import styled from "styled-components";
import { mediaMax } from "../../helpers/MediaQueries";

export const IntroImageWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  position: relative;
`;
export const IntroImageItem = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;
export const IntroImageArrow = styled.img`
  position: absolute;
  left: 50%;
  bottom: 42px;
  transform: translateX(-50%);
  width: 56px;
  cursor: pointer;
  transition: 0.4s ease-out;
  &:hover {
    opacity: 0.75;
  }
  ${mediaMax.tabletLandscape`
    bottom: 48px
  `};
`;
