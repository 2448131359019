import React, { FC, useEffect, useRef } from "react";
import amenities1 from "../../../images/amenities-1.png";
import { WellnessLeft, WellnessRight, WellnessWrapper } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Wellness: FC = () => {
  const wellnessWrapperRef = useRef<HTMLDivElement>(null);
  const wellnessRightRef = useRef<HTMLImageElement>(null);
  const wellnessHeaderRef = useRef<HTMLHeadingElement>(null);
  const wellnessTextRef = useRef<HTMLParagraphElement>(null);

  const data = useStaticQuery(graphql`
    query {
      amenities1: file(relativePath: { eq: "amenities-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: wellnessWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=30% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          wellnessRightRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          wellnessHeaderRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        )
        .fromTo(
          wellnessTextRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        ),
    });
  }, []);
  return (
    <WellnessWrapper ref={wellnessWrapperRef}>
      <WellnessLeft>
        <h2 className={"body-title"} ref={wellnessHeaderRef}>
          BEYOND YOUR HOME
        </h2>
        <p className={"body-copy"} ref={wellnessTextRef}>
          Multiple wellness spaces create an environment that inspires healthful
          living, from a pool and fitness center to a grassy park and tranquil
          cabana.
        </p>
      </WellnessLeft>
      <WellnessRight>
        <Img
          fluid={data.amenities1.childImageSharp.fluid}
          alt={"New homes for sale in Newport Beach"}
          loading={"eager"}
        />
      </WellnessRight>
    </WellnessWrapper>
  );
};

export default Wellness;
